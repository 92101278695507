import cx from 'classnames';
import type { HTMLAttributes, ReactNode } from 'react';

import { Ratio, Text } from 'components/@tedui';
import { TextProps } from 'components/@tedui/Text';

import { map } from 'f';

type TextLoaderType = Partial<TextProps> & {
  lines?: string;
};

function T({
  variant = 'body2',
  color = { color: 'primary' },
  lines = '1'
}: TextLoaderType): React.ReactNode {
  return (
    <Text variant={variant} color={color}>
      {map<number, ReactNode>(x => (
        <div
          key={x}
          className={cx(
            `w-full animate-flash select-none bg-current font-[inherit] leading-[inherit]`,
            {
              'mb-1': Number(lines) > 1
            }
          )}
        >
          {'\u00A0'}
        </div>
      ))(Array.from(Array(Number(lines)).keys()))}
    </Text>
  );
}

function RatioL({
  color = 'currentColor',
  ratio = [16, 9]
}: {
  color?: string;
  ratio?: Array<number>;
}): React.ReactNode {
  return (
    <Ratio
      className="w-full select-none"
      customStyle={{
        backgroundColor: color,
        animation: 'flash 2s infinite linear'
      }}
      ratio={ratio}
    />
  );
}

function SizeL({
  color = 'currentColor',
  height,
  width,
  ...props
}: {
  color?: string;
  height: number | string;
  width: number | string;
}): React.ReactNode {
  return (
    <div
      className="block animate-flash select-none"
      style={{
        backgroundColor: color,
        height,
        width
      }}
      {...props}
    />
  );
}

function SpinnerL({
  color = 'currentColor',
  size,
  className,
  ...props
}: {
  color?: string;
  size: number | string;
} & HTMLAttributes<HTMLDivElement>): React.ReactNode {
  return (
    <div
      className={cx(`animate-spin select-none h-${size} w-${size}`, className)}
      {...props}
    >
      <div
        className="z-1 absolute size-full rounded-full border-[4px] border-solid opacity-50"
        style={{
          borderColor: color
        }}
      />
      <div
        className="z-2 absolute size-full rounded-full border-[4px] border-solid border-transparent"
        style={{
          borderLeftColor: color
        }}
      />
    </div>
  );
}

const Loader = {
  Text: T,
  Ratio: RatioL,
  Size: SizeL,
  Spinner: SpinnerL
};

SizeL.dispalyName = 'Zenith(Loader.Size)';
T.dispalyName = 'Zenith(Loader.Text)';
RatioL.displayName = 'Zenith(Loader.Ratio)';
SpinnerL.displayName = 'Zenith(Loader.Spinner)';

export default Loader;
