// @ts-strict-ignore
import { NextRouter, useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, type JSX } from 'react';

import { arrayOrOne, flatten } from 'f';

import useVideoPlayerStore from 'components/video-player/store';
import { playlistReferrer } from 'lib/business';
import { useAutoplayPreference } from 'lib/preferences/useAutoplayPreference';
import { useSessionstorageState } from 'rooks';

type PanelStyleTokens = {
  zIndex: number;
  headerHeight: number;
};

type NavigationEntry = PerformanceNavigationTiming & {
  type: 'reload';
};

type TalkPageContext = {
  // Path components
  slug: string;
  tab: string;

  // Query components
  hasLanguage: boolean;
  language: string;
  playlistSlug: string | null;
  autoplay: boolean | null;
  subtitle: string;
  t: number;

  // Embed properties
  muted?: boolean;
  start?: number;
  showDetails?: boolean;

  // Transcript tokens
  panelStyleTokens: PanelStyleTokens;
} & NextRouter;

const Context = createContext<TalkPageContext>(null);

function sanitizePlaylistSlug(slug: string | null): string | null {
  if (!slug) return null;
  // Remove any query parameters after ? or #
  return slug.split(/[?#]/)[0];
}

export function TalkPageContextProvider({ children }): JSX.Element {
  const { reset, setShouldAutoplay, playbackMode } = useVideoPlayerStore(
    state => ({
      reset: state.reset,
      setShouldAutoplay: state.setShouldAutoplay,
      playbackMode: state.playbackMode
    })
  );

  const [isMuted] = useSessionstorageState('muted_state', false);

  const router = useRouter();
  const isEmbed = router.asPath.includes('embed');

  const {
    slug: pathComponents,
    language: possibleLanguages,
    subtitle: possibleSubtitles,
    referrer,
    autoplay: autoplayQuery,
    t,
    muted: mutedQuery,
    start: startQuery,
    showDetails: showDetailsQuery
  } = router.query;

  const [slug, tab] = flatten([pathComponents]);
  const subtitle = arrayOrOne(possibleSubtitles);

  // Extract language from path for embed route
  let language;
  if (router.pathname.startsWith('/embed/talks/lang/')) {
    const pathSegments = router.asPath.split('/');
    const langIndex = pathSegments.findIndex(seg => seg === 'lang');
    language = pathSegments[langIndex + 1];
  } else {
    // Use query parameter for normal talks route
    language = arrayOrOne(possibleLanguages);
  }

  const panelStyleTokens = {
    zIndex: 1000,
    headerHeight: 40
  };

  const [autoplayPreference] = useAutoplayPreference();

  const value = useMemo(() => {
    const rawPlaylistSlug = referrer ? playlistReferrer(referrer) : null;
    const sanitizedPlaylistSlug = sanitizePlaylistSlug(rawPlaylistSlug);

    // Check if this is an internal navigation (coming from within the site)
    const isInternalNavigation =
      typeof window !== 'undefined' && referrer?.includes(window.location.host);

    // Get navigation type safely
    const isPageReload =
      typeof window !== 'undefined'
        ? (performance?.getEntriesByType('navigation')?.[0] as NavigationEntry)
            ?.type === 'reload'
        : false;

    // Determine autoplay state
    const shouldAutoplay =
      playbackMode === 'replayed' || // Always autoplay on replay
      (autoplayQuery === 'true' && !isEmbed) || // Autoplay query parameter takes precedence
      (!isEmbed && autoplayPreference) || // User preference
      isInternalNavigation || // Autoplay on internal navigation
      isPageReload; // Autoplay on page reload

    return {
      ...router,
      slug,
      tab,
      subtitle,
      language: language || 'en',
      hasLanguage: Boolean(language),
      playlistSlug: sanitizedPlaylistSlug,
      autoplay: shouldAutoplay,
      t: Number(t),
      muted: mutedQuery === 'true' || isMuted,
      start: Number(startQuery),
      showDetails: Boolean(showDetailsQuery)
    } as TalkPageContext;
  }, [
    router,
    slug,
    tab,
    subtitle,
    language,
    referrer,
    autoplayQuery,
    t,
    mutedQuery,
    isMuted,
    startQuery,
    showDetailsQuery,
    isEmbed,
    playbackMode,
    autoplayPreference
  ]);

  useEffect(() => {
    // Don't set autoplay state until we have the actual preference value
    if (autoplayPreference === null) return;

    // Check if this is an internal navigation
    const isInternalNavigation = referrer?.includes(window?.location?.host);

    // Set autoplay if any of these conditions are true:
    // 1. It's a replay
    // 2. Both the query parameter AND user preference are true
    // 3. It's an internal navigation
    // 4. It's a page reload
    const shouldSetAutoplay =
      playbackMode === 'replayed' ||
      (autoplayQuery === 'true' && autoplayPreference) ||
      isInternalNavigation ||
      (typeof window !== 'undefined' &&
        (
          performance.getEntriesByType(
            'navigation'
          )[0] as PerformanceNavigationTiming
        )?.type === 'reload');

    setShouldAutoplay(shouldSetAutoplay);
  }, [
    value.autoplay,
    setShouldAutoplay,
    playbackMode,
    autoplayPreference,
    autoplayQuery,
    referrer
  ]);

  useEffect(() => {
    // Reset the video player state when navigating to a different talk
    reset('nextTalk');
  }, [slug]);

  return (
    <Context
      value={{
        ...value,
        panelStyleTokens
      }}
    >
      {children}
    </Context>
  );
}

export function useTalkPageContext(): TalkPageContext {
  return useContext(Context);
}
