import { AddToList } from 'components/pages/talks/slug/TalkDetails/TalkActions/AddToList';
import GenericButton from './GenericButton';

type GenericButtonProps = {
  handleClick: () => void;
  isActionRunning: boolean;
  isCurrentTalkInYourList: boolean;
  isDarkMode: boolean;
};

type CardAddToListProps = {
  id: string;
  isOpen: boolean;
  isDarkMode?: boolean;
  mixpanelTitle?: string;
};

const CustomAddToListButton = ({
  handleClick,
  isActionRunning,
  isCurrentTalkInYourList,
  isDarkMode
}: GenericButtonProps) => (
  <GenericButton
    handleClick={handleClick}
    isActionRunning={isActionRunning}
    isActive={isCurrentTalkInYourList}
    isDarkMode={isDarkMode}
    type="add-to-list"
  />
);

const CardAddToList = ({
  id,
  isOpen,
  isDarkMode,
  mixpanelTitle
}: CardAddToListProps) => {
  return (
    <AddToList
      id={id}
      customComponent={CustomAddToListButton}
      homepageTooltipIsOpen={isOpen}
      isDarkMode={isDarkMode}
      mixpanelTitle={mixpanelTitle}
    />
  );
};

export default CardAddToList;
