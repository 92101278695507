import type { Video } from 'api';
import cx from 'classnames';
import CardToolTip from 'components/card-tooltip';
import ThreeDots from 'icons/ThreeDotsIcon';
import mixpanel from 'lib/analytics/mixpanel';
import { useCallback, useEffect, useState } from 'react';
import { useTooltipStore } from './store';

interface AnimatedIconButtonProps {
  onClick?: () => void;
  callback?: (value: boolean) => void;
  talk: unknown;
  isDarkMode?: boolean;
  mixpanelTitle?: string;
  itemNumber?: string;
}

const AnimatedIconButton = ({
  callback,
  onClick,
  talk,
  isDarkMode = false,
  mixpanelTitle,
  itemNumber = ''
}: AnimatedIconButtonProps) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const { onHideTooltip } = useTooltipStore();

  const handleClick = () => {
    if (onClick) onClick();
    setIsClicked(true);
    setIsTooltipOpen(true);
    onHideTooltip();
    setTimeout(() => setIsClicked(false), 500);
    if (mixpanelTitle)
      mixpanel.track('homepage_click', {
        current_url: window.location.href,
        title: mixpanelTitle,
        item_number: itemNumber,
        link_type: 'overflow',
        value: ''
      });
  };

  const onHide = useCallback(() => setIsTooltipOpen(false), []);

  useEffect(() => {
    if (callback) callback(isTooltipOpen);
  }, [isTooltipOpen]);

  useEffect(() => {
    const updateOnResize = () => {
      onHide();
    };

    window.addEventListener('resize', updateOnResize);

    return () => {
      window.removeEventListener('resize', updateOnResize);
    };
  }, []);

  return (
    <CardToolTip
      isOpen={isTooltipOpen}
      onHide={onHide}
      talk={talk as Partial<Video>}
      isDarkMode={isDarkMode}
      mixpanelTitle={mixpanelTitle as string}
    >
      <div
        onClick={handleClick}
        className={cx(
          'relative rounded-full transition-colors duration-300 ease-in-out',
          {
            'bg-gray-300 ring-2 ring-[#D0D0D0]': isClicked,
            'bg-transparent': !isClicked
          }
        )}
      >
        <ThreeDots
          className={cx(
            'h-4 lg-tui:h-5',
            isDarkMode ? 'dark-svg-icon dark-svg-icon_fill' : ''
          )}
        />
      </div>
    </CardToolTip>
  );
};

export default AnimatedIconButton;
