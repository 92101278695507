import SkeletonItem from '../../SkeletonItem';
import ContentContainer from 'components/content-container';

export const ImageHereRibbonSkeleton = ({
  isMobileWidth,
  classes: {
    headerClasses,
    ribbonClasses,
    ribbonCardsClasses,
    mainCardRibbon,
    mainCardRibbonDescription
  }
}) => (
  <ContentContainer
    className="relative xs-tui:pb-10 sm-tui:pb-10 md-tui:pb-12 lg-tui:pb-16 xl-tui:pb-16"
    noPadding={isMobileWidth}
  >
    <div id="slide-header" className={headerClasses}>
      <SkeletonItem className="h-[80px] w-[100px]" />
      <div className="max-w-[600px]">
        <div className="mt-3 flex max-w-[600px] flex-col gap-3 md:w-[600px]">
          {Array.from({ length: 4 }).map((_, index) => (
            <SkeletonItem key={`${index}`} className="h-4 sm:w-full" />
          ))}
        </div>
      </div>
    </div>
    <div className="mt-5 w-full">
      <SkeletonItem className="sm:h-[200px] md:h-[600px]" />
    </div>
    <div className={ribbonClasses}>
      <div className={mainCardRibbon}>
        <div className="mb-3 mt-2">
          <SkeletonItem className="h-4 w-8" />
        </div>
        {!isMobileWidth && (
          <div className="mb-3 mt-2">
            <div className="flex flex-col gap-3">
              {Array.from({ length: 4 }).map((_, index) => (
                <SkeletonItem key={`${index}`} className="h-4 w-full" />
              ))}
            </div>
          </div>
        )}

        <div className={mainCardRibbonDescription}>
          <SkeletonItem className="h-4 w-full" />
        </div>
      </div>

      {Array.from({ length: 2 }).map((_item, index) => {
        return (
          <div className={ribbonCardsClasses} key={`${index}`}>
            <div>
              <SkeletonItem className="h-[115px] w-full" />
            </div>

            <div className="my-3">
              <SkeletonItem className="h-4 w-16" />
            </div>
            <div className="flex flex-col gap-3">
              <SkeletonItem className="h-4 w-full" />
              <SkeletonItem className="h-4 w-full" />
            </div>
          </div>
        );
      })}
    </div>
    {isMobileWidth && <div className="my-6 flex w-full justify-end"></div>}
  </ContentContainer>
);

export default ImageHereRibbonSkeleton;
