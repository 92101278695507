import { useEffect } from 'react';

import { useShortURL } from 'lib/hooks/useShortURL';

function useShortURLToolTip(url: string, isOpen: boolean) {
  const { error, loading, refetch, shortURL } = useShortURL(url);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  return { shortURL, loading, error };
}

export default useShortURLToolTip;
