import create from 'zustand';
import storage from 'local-storage-fallback';
import { isEmpty } from 'lodash-es';

export const STORAGE_KEY = 'three-dots-feature_dismissed';

export const [useTooltipStore] = create(set => ({
  isDismissed: true,

  onHideTooltip: () => {
    storage.setItem(STORAGE_KEY, 'true');
    set({ isDismissed: true });
  },

  onShowTooltip: () => {
    storage.setItem(STORAGE_KEY, 'false');
    set({ isDismissed: false });
  },

  setSurveyDismissed: (value: boolean) => {
    set({ isDismissed: value });
  },

  getStoredValue: () => {
    const storedValue = storage.getItem(STORAGE_KEY);

    if (isEmpty(storedValue)) {
      storage.setItem(STORAGE_KEY, 'false');
      set({ isDismissed: false });
    } else {
      set({ isDismissed: storedValue === 'true' });
    }
  }
}));
