import cx from 'classnames';
import { Text } from 'components/@tedui';
import Share from 'components/pages/talks/slug/TalkDetails/TalkActions/Share';
import ShareIcon from 'icons/ShareIcon';
import { useRef, type JSX } from 'react';

type CardShareProps = {
  slug: string;
  title: string;
  presenters: string;
  context: string;
  isOpen: boolean;
  isDarkMode: boolean;
  shortenedUrl: string;
  onHideTooltip?: () => void;
  mixpanelTitle: string;
};

const CustomLikeButton = ({ handleClick, isDarkMode }) => {
  const buttonClasses = cx(
    'flex min-h-5 w-full min-w-5 items-center gap-2 rounded-b-lg px-3 py-2',
    {
      'dark-svg-icon dark-svg-icon_fill hover:bg-gray-400': isDarkMode,
      'hover:bg-gray-300': !isDarkMode
    }
  );
  return (
    <div>
      <button className={buttonClasses} onClick={() => handleClick()}>
        <ShareIcon className="size-5" />
        <Text
          variant="caption1"
          useNewTextStyles
          color={{ override: isDarkMode ? '#fff' : '#000' }}
        >
          Share
        </Text>
      </button>
    </div>
  );
};

const CardShare = ({
  slug,
  title,
  presenters,
  context,
  shortenedUrl,
  isOpen,
  isDarkMode,
  onHideTooltip = () => null,
  mixpanelTitle
}: CardShareProps): JSX.Element => {
  const leftRef = useRef(null);
  return (
    <Share
      ref={leftRef}
      slug={slug}
      title={title}
      presenters={presenters}
      context={context}
      shortenedUrl={shortenedUrl}
      customComponent={CustomLikeButton}
      onHideTooltip={onHideTooltip}
      homepageTooltipIsOpen={isOpen}
      isDarkMode={isDarkMode}
      mixpanelTitle={mixpanelTitle}
    />
  );
};

export default CardShare;
