import { useEffect, useState } from 'react';

interface AutoplayPreference {
  isEnabled: boolean;
}

export function useAutoplayPreference(): [
  boolean | null,
  (value: boolean) => void
] {
  const [autoplayPreference, setAutoplayPreference] = useState<boolean | null>(
    null
  );

  const setPreference = (value: boolean) => {
    try {
      const preference: AutoplayPreference = { isEnabled: value };
      localStorage.setItem('autoplay_preference', JSON.stringify(preference));
      setAutoplayPreference(value);
    } catch (error) {
      console.error('Error saving autoplay preference:', error);
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    try {
      const preference = localStorage.getItem('autoplay_preference');
      if (preference) {
        const parsed = JSON.parse(preference) as AutoplayPreference;
        setAutoplayPreference(parsed.isEnabled);
      } else {
        setPreference(true);
      }
    } catch (error) {
      console.error('Error reading autoplay preference:', error);
      setPreference(true);
    }
  }, []);

  return [autoplayPreference, setPreference];
}
