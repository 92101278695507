import type { JSX } from 'react';

type HeartIconProps = {
  className?: string;
  variant: 'fill' | 'default';
};

const HeartIcon = (props: HeartIconProps): JSX.Element => {
  const { className, variant } = props;
  if (variant === 'fill') {
    return (
      <svg
        className={className || ''}
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49181 2.09019C8.93426 1.90686 9.40848 1.8125 9.88741 1.8125C10.3663 1.8125 10.8406 1.90686 11.283 2.09019C11.7254 2.27351 12.1274 2.54222 12.466 2.88095C12.8047 3.21948 13.0733 3.62142 13.2566 4.0638C13.4399 4.50625 13.5343 4.98048 13.5343 5.4594C13.5343 5.93832 13.4399 6.41255 13.2566 6.85499C13.0733 7.29741 12.8046 7.69937 12.4659 8.03792C12.4659 8.03795 12.466 8.0379 12.4659 8.03792L7.30927 13.1946C7.13841 13.3654 6.8614 13.3654 6.69055 13.1946L1.53388 8.03792C0.850013 7.35406 0.46582 6.42653 0.46582 5.4594C0.46582 4.49226 0.850013 3.56474 1.53388 2.88087C2.21775 2.197 3.14527 1.81281 4.11241 1.81281C5.07954 1.81281 6.00706 2.197 6.69093 2.88087L6.99991 3.18985L7.30881 2.88095C7.64738 2.54222 8.04937 2.27351 8.49181 2.09019Z"
          fill="#121212"
        />
      </svg>
    );
  }
  return (
    <svg
      className={className || ''}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heart">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49181 2.09019C8.93426 1.90686 9.40848 1.8125 9.88741 1.8125C10.3663 1.8125 10.8406 1.90686 11.283 2.09019C11.7254 2.27351 12.1274 2.54222 12.466 2.88095C12.8047 3.21948 13.0733 3.62142 13.2566 4.0638C13.4399 4.50625 13.5343 4.98048 13.5343 5.4594C13.5343 5.93832 13.4399 6.41255 13.2566 6.85499C13.0733 7.29741 12.8046 7.69937 12.4659 8.03792C12.4659 8.03795 12.466 8.0379 12.4659 8.03792L7.30927 13.1946C7.13841 13.3654 6.8614 13.3654 6.69055 13.1946L1.53388 8.03792C0.850013 7.35406 0.46582 6.42653 0.46582 5.4594C0.46582 4.49226 0.850013 3.56474 1.53388 2.88087C2.21775 2.197 3.14527 1.81281 4.11241 1.81281C5.07954 1.81281 6.00706 2.197 6.69093 2.88087L6.99991 3.18985L7.30881 2.88095C7.64738 2.54222 8.04937 2.27351 8.49181 2.09019ZM11.8471 3.49952C11.5898 3.24208 11.2843 3.03787 10.9481 2.89854C10.6118 2.75921 10.2514 2.6875 9.88741 2.6875C9.52343 2.6875 9.16301 2.75921 8.82675 2.89854C8.4905 3.03787 8.18498 3.24208 7.92767 3.49952L7.30927 4.11792C7.13841 4.28878 6.8614 4.28878 6.69055 4.11792L6.07221 3.49959C5.55244 2.97982 4.84748 2.68781 4.11241 2.68781C3.37734 2.68781 2.67237 2.97982 2.1526 3.49959C1.63283 4.01936 1.34082 4.72433 1.34082 5.4594C1.34082 6.19447 1.63283 6.89943 2.1526 7.41921L6.99991 12.2665L11.8472 7.41921C12.1046 7.16189 12.3089 6.85631 12.4483 6.52005C12.5876 6.18379 12.6593 5.82338 12.6593 5.4594C12.6593 5.09542 12.5876 4.735 12.4483 4.39875C12.3089 4.06249 12.1046 3.75683 11.8471 3.49952Z"
          fill="#121212"
        />
      </g>
    </svg>
  );
};
export default HeartIcon;
