import { Like } from 'components/pages/talks/slug/TalkDetails/TalkActions/Like';
import GenericButton from './GenericButton';

import type { JSX } from 'react';

type CardLikeProps = {
  id: string;
  isOpen: boolean;
  isDarkMode: boolean;
  mixpanelTitle: string;
};

const CustomLikeButton = ({
  handleClick,
  isActionRunning,
  isCurrentTalkInYourLikes,
  isDarkMode
}) => (
  <GenericButton
    handleClick={handleClick}
    isActionRunning={isActionRunning}
    isActive={isCurrentTalkInYourLikes}
    isDarkMode={isDarkMode}
    type="like"
  />
);

const CardLike = ({
  id,
  isOpen,
  isDarkMode,
  mixpanelTitle
}: CardLikeProps): JSX.Element => {
  return (
    <Like
      id={id}
      customComponent={CustomLikeButton}
      homepageTooltipIsOpen={isOpen}
      isDarkMode={isDarkMode}
      mixpanelTitle={mixpanelTitle}
    />
  );
};

export default CardLike;
