import type { JSX } from 'react';

type AddToListIconProps = {
  className?: string;
  variant: 'add-updated' | 'add' | 'added' | 'added-updated';
};

const AddToListIcon = (props: AddToListIconProps): JSX.Element => {
  const { className, variant } = props;
  switch (variant) {
    case 'add':
      return (
        <svg
          className={className || ''}
          viewBox="0 0 26 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.36353 4.36364H15.909"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.36353 10.5455H15.909"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.2728 20.7273V11.6364"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.9091 16.3636H25"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.36368 16.3636H10.4546"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'add-updated':
      return (
        <svg
          className={className || ''}
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.709961 3.27344C0.709961 2.96278 0.961801 2.71094 1.27246 2.71094H12.1816C12.4922 2.71094 12.7441 2.96278 12.7441 3.27344C12.7441 3.5841 12.4922 3.83594 12.1816 3.83594H1.27246C0.961801 3.83594 0.709961 3.5841 0.709961 3.27344Z"
            fill="#121212"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.709961 7.90625C0.709961 7.59559 0.961801 7.34375 1.27246 7.34375H12.1816C12.4922 7.34375 12.7441 7.59559 12.7441 7.90625C12.7441 8.21691 12.4922 8.46875 12.1816 8.46875H1.27246C0.961801 8.46875 0.709961 8.21691 0.709961 7.90625Z"
            fill="#121212"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5225 8.4375C15.8332 8.4375 16.085 8.68934 16.085 9V15.8182C16.085 16.1288 15.8332 16.3807 15.5225 16.3807C15.2119 16.3807 14.96 16.1288 14.96 15.8182V9C14.96 8.68934 15.2119 8.4375 15.5225 8.4375Z"
            fill="#121212"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5623 12.2727C19.5623 12.5834 19.3105 12.8352 18.9998 12.8352H12.1816C11.871 12.8352 11.6191 12.5834 11.6191 12.2727C11.6191 11.9621 11.871 11.7102 12.1816 11.7102H18.9998C19.3105 11.7102 19.5623 11.9621 19.5623 12.2727Z"
            fill="#121212"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.65332 12.2734C8.65332 12.5841 8.40148 12.8359 8.09082 12.8359H1.27264C0.961978 12.8359 0.710138 12.5841 0.710138 12.2734C0.710138 11.9628 0.961978 11.7109 1.27264 11.7109H8.09082C8.40148 11.7109 8.65332 11.9628 8.65332 12.2734Z"
            fill="#121212"
          />
        </svg>
      );
    case 'added':
      return (
        <svg
          className={className || ''}
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.30273 3.63604H13.4239"
            stroke="#2763FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.30273 8.78741H13.4239"
            stroke="#2763FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 11.667L15.8438 17.5003L13.5 14.8488"
            stroke="#2763FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.30315 13.636H8.87891"
            stroke="#2763FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'added-updated':
      return (
        <svg
          className={className || ''}
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.30273 3.63604H13.4239"
            stroke="#121212"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.30273 8.78741H13.4239"
            stroke="#121212"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 11.667L15.8438 17.5003L13.5 14.8488"
            stroke="#121212"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.30315 13.636H8.87891"
            stroke="#121212"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return <></>;
  }
};
export default AddToListIcon;
