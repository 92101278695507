import type { JSX } from 'react';

type ShareIconProps = {
  className?: string;
};

const ShareIcon = (props: ShareIconProps): JSX.Element => {
  const { className } = props;
  return (
    <svg
      className={className || ''}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="send" clip-path="url(#clip0_6450_30143)">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0199 0.982398C15.2152 1.17766 15.2152 1.49424 15.0199 1.6895L7.68656 9.02284C7.4913 9.2181 7.17472 9.2181 6.97946 9.02284C6.78419 8.82758 6.78419 8.51099 6.97946 8.31573L14.3128 0.982398C14.5081 0.787136 14.8246 0.787136 15.0199 0.982398Z"
            fill="#121212"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0199 0.982398C15.1557 1.11822 15.2017 1.31983 15.1383 1.50113L10.4716 14.8345C10.4039 15.028 10.2249 15.1605 10.0201 15.1689C9.81522 15.1772 9.62603 15.0597 9.54277 14.8724L6.95421 9.04809L1.12994 6.45952C0.942601 6.37626 0.825069 6.18708 0.833426 5.98224C0.841783 5.7774 0.974335 5.59841 1.16784 5.53069L14.5012 0.864022C14.6825 0.80057 14.8841 0.846579 15.0199 0.982398ZM2.68855 6.05792L7.53608 8.21238C7.6492 8.26265 7.73964 8.3531 7.78992 8.46622L9.94437 13.3137L13.8514 2.15094L2.68855 6.05792Z"
            fill="#121212"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6450_30143">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ShareIcon;
