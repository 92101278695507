import type { JSX } from 'react';

type ThreeDotsIconProps = {
  className?: string;
};

const ThreeDotsIcon = (props: ThreeDotsIconProps): JSX.Element => {
  const { className } = props;
  return (
    <svg
      className={className || ''}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="6" r="1.5" fill="#222222" />
      <circle cx="12" cy="12" r="1.5" fill="#222222" />
      <circle cx="12" cy="18" r="1.5" fill="#222222" />
    </svg>
  );
};
export default ThreeDotsIcon;
