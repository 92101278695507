import { BadgeText } from '.';

export function getBadgeText(name: string): BadgeText | null {
  if (name === 'Custom sponsored content') {
    return {
      large: 'Partner content',
      small: 'Partner'
    };
  }

  return null;
}
