import cx from 'classnames';

import {
  TooltipContent,
  TooltipRoot,
  TooltipTrigger
} from 'components/shared/Tooltip';
import AddToList from './card-actions/AddToList';
import Like from './card-actions/Like';
import Share from './card-actions/Share';

import { Video } from 'api';
import type { JSXElementConstructor, ReactElement } from 'react';
import useShortURLToolTip from './hooks/useShortURLToolTip';

type CardToolTipProps = {
  talk: Partial<Video>;
  isOpen?: boolean;
  isDarkMode?: boolean;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  onHide: () => void;
  mixpanelTitle: string;
};

const CardToolTip = ({
  isOpen = false,
  children,
  onHide,
  talk,
  isDarkMode = false,
  mixpanelTitle
}: CardToolTipProps): React.ReactNode => {
  const { shortURL } = useShortURLToolTip(`${talk.canonicalUrl || ''}`, isOpen);

  const cardClasses = cx(
    'flex flex-col rounded-lg shadow-[0px_4px_15px_0px_rgba(0,0,0,0.1)]',
    {
      'bg-textSecondary-onLight': isDarkMode,
      'bg-textPrimary-onDark': !isDarkMode
    }
  );

  return (
    <TooltipRoot open={isOpen} onOpenChange={open => !open && onHide()}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent
        side="bottom"
        align="start"
        sideOffset={5}
        avoidCollisions={false}
        className="border-0 bg-transparent p-0 shadow-none"
      >
        <div className={cardClasses}>
          <AddToList
            id={talk.id || ''}
            isOpen={isOpen}
            isDarkMode={isDarkMode}
            mixpanelTitle={mixpanelTitle}
          />
          <Like
            id={talk.id || ''}
            isOpen={isOpen}
            isDarkMode={isDarkMode}
            mixpanelTitle={mixpanelTitle}
          />
          <Share
            slug={talk.slug || ''}
            title={talk.title || ''}
            presenters={talk.presenterDisplayName || ''}
            context={talk.videoContext || ''}
            shortenedUrl={shortURL || ''}
            onHideTooltip={onHide}
            isOpen={isOpen}
            isDarkMode={isDarkMode}
            mixpanelTitle={mixpanelTitle}
          />
        </div>
      </TooltipContent>
    </TooltipRoot>
  );
};

export default CardToolTip;
