import { sendErrorToServices } from 'lib/logging';
import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

function useShortURL(url: string) {
  const [shortURL, setShortURL] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { refetch } = useQuery({
    queryKey: ['shortenURL', url],
    queryFn: async () => {
      setLoading(true);
      setError(null);

      if (typeof window === 'undefined') {
        setLoading(false);
        setError('window is not defined');
        return;
      }
      const urlToShorten = url;

      try {
        const formData = new URLSearchParams({ url: urlToShorten });

        const response = await fetch(
          process.env.NEXT_PUBLIC_URL_SHORTENER_URL,
          {
            method: 'POST',
            body: formData.toString(),
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to get the short URL. Status: ${response.status}`
          );
        }

        const location = response.headers.get('location');

        if (!location) {
          throw new Error('No location header in response');
        }

        setShortURL(location);
        return location;
      } catch (err) {
        const error = err as Error;
        setError(error.message);
        sendErrorToServices('Failed to get the short URL', error.message);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    enabled: false
  });

  return { shortURL, loading, error, refetch };
}

export { useShortURL };
