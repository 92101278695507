import cx from 'classnames';

import { Text } from 'components/@tedui';
import Loader from 'components/loaders';

import AddToListIcon from 'icons/AddToListIcon';
import Heart from 'icons/HeartIcon';

type GenericActionButtonProps = {
  handleClick: () => void;
  isActionRunning: boolean;
  isActive: boolean;
  isDarkMode: boolean;
  type: 'like' | 'add-to-list';
};

const GenericActionButton = ({
  handleClick,
  isActionRunning,
  isActive,
  isDarkMode,
  type
}: GenericActionButtonProps) => {
  const buttonClasses = cx(
    'flex min-h-5 w-full min-w-5 items-center gap-2 px-3 py-2 disabled:cursor-not-allowed',
    {
      'rounded-t-lg': type !== 'like',
      'hover:bg-gray-400': isDarkMode,
      'hover:bg-gray-300': !isDarkMode,
      disabled: isActionRunning
    }
  );

  const renderIcon = () => {
    if (isActionRunning) {
      return (
        <Loader.Spinner
          size="5"
          className="self-center"
          color={isDarkMode ? '#fff' : '#000'}
        />
      );
    }

    if (type === 'like') {
      return isActive ? (
        <Heart
          variant="fill"
          className={`size-5 ${isDarkMode ? 'dark-svg-icon dark-svg-icon_fill' : ''}`}
        />
      ) : (
        <Heart
          variant="default"
          className={`size-5 ${isDarkMode ? 'dark-svg-icon dark-svg-icon_fill' : ''}`}
        />
      );
    } else {
      return isActive ? (
        <AddToListIcon
          variant="added-updated"
          className={`size-5 ${isDarkMode ? 'dark-svg-icon dark-svg-icon_stroke' : ''}`}
        />
      ) : (
        <AddToListIcon
          variant="add-updated"
          className={`size-5 ${isDarkMode ? 'dark-svg-icon dark-svg-icon_fill' : ''}`}
        />
      );
    }
  };

  const renderButtonText = () => {
    if (type === 'like') {
      return isActive ? 'Liked' : 'Like';
    } else {
      return isActive ? 'Saved to Playlist' : 'Save to Playlist';
    }
  };

  return (
    <button
      className={buttonClasses}
      onClick={handleClick}
      disabled={isActionRunning}
    >
      {renderIcon()}
      <Text
        variant="caption1"
        useNewTextStyles
        color={{ override: isDarkMode ? '#FFFFFF' : '#000' }}
      >
        {renderButtonText()}
      </Text>
    </button>
  );
};

export default GenericActionButton;
